<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

export default {
  components: {
    Layout,
    PageHeader
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "eCommerce Points Pool",
          active: true
        }
      ]
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="eCommerce Points Pool" :items="getItems()"/>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-6 d-flex justify-content-end align-items-center">
                <img src="@/assets/images/soon/ecommerce-points-pool.png" alt="eCommerce Points Pool" class="img-fluid" />
              </div>

              <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
                <span class="badge badge-soft-primary font-size-14 mb-4">{{ $t('new-ecat.coming-soon') }}</span>
                <h1>ECOMMERCE POINTS POOL</h1>

                <div class="row">
                  <div class="col-12 col-lg-8">
                    <h6 class="text-gray-700">{{ $t('ecommerce-points-pool.description') }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.badge {
  padding: 0.8rem 1.5rem !important;
  border-radius: 1rem !important;
}
</style>